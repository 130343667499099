var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5"},[_c('validation-observer',{ref:"simpleRules"},[_c('b-card',[_c('validation-provider',{attrs:{"name":"File","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("File")]),_c('b-form-file',{attrs:{"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.isProcessing && _vm.$store.state.app.fileUploadProgress < 100)?_c('b-col',{staticClass:"pt-2",attrs:{"md":"12"}},[_c('b-progress',{attrs:{"max":100}},[_c('b-progress-bar',{attrs:{"value":_vm.$store.state.app.fileUploadProgress}},[_c('strong',[_vm._v(_vm._s(_vm.$store.state.app.fileUploadProgress.toFixed(2))+" %")])])],1)],1):_vm._e(),_c('b-row',{staticClass:"my-2"},[_c('b-col',[_c('validation-provider',{attrs:{"name":"Version","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Version")]),_c('b-form-input',{attrs:{"placeholder":"version"},model:{value:(_vm.version),callback:function ($$v) {_vm.version=$$v},expression:"version"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',[_c('label',[_vm._v("Platform")]),_c('validation-provider',{attrs:{"name":"Platform","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"placeholder":"version","options":_vm.platformList},model:{value:(_vm.platform),callback:function ($$v) {_vm.platform=$$v},expression:"platform"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',[_c('label',[_vm._v("Architecture")]),_c('validation-provider',{attrs:{"name":"Architecture","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"placeholder":"version","options":_vm.architectureList},model:{value:(_vm.architecture),callback:function ($$v) {_vm.architecture=$$v},expression:"architecture"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('label',[_vm._v("Description")]),_c('quill-editor',{model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"switch":""}},[_vm._v(" Force Update ")]),_c('b-button',{staticClass:"mt-1 float-right",attrs:{"variant":"primary","disabled":_vm.isProcessing},on:{"click":_vm.uploadFile}},[(_vm.isProcessing)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" Upload ")],1)],1)],1),_c('b-tabs',{attrs:{"pills":""}},_vm._l((_vm.platformList),function(list){return _c('b-tab',{key:list.value,attrs:{"title":list.text,"lazy":""}},[_c('list-files',{key:_vm.updateKey,attrs:{"platform":list.value,"architecture-list":_vm.architectureList}})],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }