<template>
  <b-tabs>
    <b-tab
      v-for="architecture of architectureList"
      :key="architecture.value"
      :title="architecture.text"
    >
      <b-overlay :show="isProcessing">
        <b-list-group>
          <b-list-group-item
            v-for="apps of applications.filter(app => app.architecture === architecture.value )"
            :key="apps.id"
            class="flex-column align-items-start"
          >
            <div
              class="d-flex w-100 justify-content-between"
              role="button"
              @click="apps.visible = !apps.visible"
            >
              <h5 class="mb-1">
                {{ apps.version }} {{ getArchitecture(apps.architecture) }}
              </h5>
              <div>
                <a
                  :href="apps.filename"
                  :download="apps.description"
                  target="_blank"
                >
                  <feather-icon
                    icon="DownloadCloudIcon"
                    size="20"
                    role="button"
                  />
                </a>
                <small class="text-secondary ml-1">
                  {{ fromNow(apps.createdAt) }}
                </small>
              </div>
            </div>
            <b-collapse v-model="apps.visible">
              <b-card v-html="apps.description" />
            </b-collapse>
          </b-list-group-item>
        </b-list-group>
      </b-overlay>
    </b-tab>
  </b-tabs>
</template>
<script>
import {
  BListGroup, BListGroupItem, BOverlay, BCollapse, BCard, BTab, BTabs,
} from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BOverlay,
    BCollapse,
    BCard,
    BTab,
    BTabs,
  },
  props: {
    platform: {
      type: String,
      required: true,
    },
    architectureList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isProcessing: false,
      applications: [],
    }
  },
  created() {
    this.getApplication()
  },
  methods: {
    getApplication() {
      this.isProcessing = true
      useApollo.company.getApplication({
        platforms: [this.platform],
      }).then(response => {
        this.applications = response.data.applications?.data.map(apps => ({
          ...apps,
          visible: false,
        }))
      }).finally(() => {
        this.isProcessing = false
      })
    },
    getArchitecture(architecture) {
      return this.architectureList.find(list => list.value === architecture)?.text
    },
  },
}
</script>
