export const ARCHITECTURES = [
  { text: 'x64', value: 'X64' },
  { text: 'x86', value: 'X86' },
  { text: 'arm64', value: 'ARM64' },
  { text: 'ia32', value: 'IA32' },
  { text: 'amd64', value: 'AMD64' },
  { text: 'ia64', value: 'IA64' },
]

export const getImages = platform => {
  switch (platform) {
    case 'MAC':
      return 'https://upload.wikimedia.org/wikipedia/commons/a/ab/Icon-Mac.svg'
    case 'WINDOWS':
      return 'https://upload.wikimedia.org/wikipedia/commons/4/48/Windows_logo_-_2012_%28dark_blue%29.svg'
    case 'LINUX':
      return 'https://upload.wikimedia.org/wikipedia/commons/a/ab/Linux_Logo_in_Linux_Libertine_Font.svg'
    default:
      return ''
  }
}

export const getSubtitle = platform => {
  switch (platform) {
    case 'MAC':
      return 'For MAC Users'
    case 'WINDOWS':
      return 'For Windows Users'
    case 'LINUX':
      return 'For Unix Users'
    default:
      return ''
  }
}
